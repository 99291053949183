export function isMobile() {
	return /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
		navigator.userAgent,
	);
}

export function dateParse(date) {
	let dateValue = new Date(date);

	if (dateValue instanceof Date && Number.isNaN(dateValue)) {
		dateValue = new Date();
	}

	const y = dateValue.getFullYear();
	const m = (dateValue.getMonth() + 1).toString().padStart(2, '0');
	const d = dateValue.getDate().toString().padStart(2, '0');

	return `${y}-${m}-${d}`;
}

export function range(start, stop, step = 1) {
	return Array(Math.ceil((stop - start) / step))
		.fill(start)
		.map((x, y) => x + y * step);
}

export function shouldHidePlaidBtn(revenue, start) {
	let shouldHide = true;

	let revenueValue = 0;
	try {
		if (revenue) {
			revenueValue = parseInt(revenue.replace(/\D/g, ''), 10);
			if (Number.isNaN(revenueValue)) {
				throw new Error(`Could not parse revenue ${revenue}`);
			}
		}
	} catch (error) {
		/* eslint-disable-next-line no-console */
		console.error(error.message);
	}

	if (typeof start !== 'number' || Number.isNaN(start) || !start) {
		return shouldHide;
	}

	const businessStart = new Date(start);

	const currentDate = new Date();
	if (
		revenueValue > 25000 &&
		currentDate.getMonth() + 1 - (businessStart.getMonth() + 1) >= 3
	) {
		shouldHide = false;
	}

	return shouldHide;
}
