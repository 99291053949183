import React from 'react';

export default React.createContext({
	user: null,
	setUser: () => {},
	application: null,
	setApplication: () => {},
	loading: false,
	setLoading: () => {},
	error: null,
	setError: () => {},
	refreshUser: () => {},
	logout: () => {},
});
