import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import UserContext from '../contexts/user.context';
import { auth } from '../utils/firebase.utils';

export function UserProvider({ children }) {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(
			auth,
			(firebaseUser) => {
				setLoading(true);
				if (firebaseUser) {
					// user logged in
					const { email, emailVerified, uid, displayName } = firebaseUser;
					setUser({ email, emailVerified, uid, displayName });
				} else {
					// user logged out
					setUser(null);
				}
				setLoading(false);
			},
			(err) => {
				setError(err);
				setLoading(false);
			},
		);
		return unsubscribe;
	}, []);

	const logout = useCallback(async () => {
		setLoading(true);
		try {
			await axios.get('/auth/signout', { withCredentials: true });
			await signOut(auth);
		} catch (err) {
			setError(err);
		} finally {
			setLoading(false);
		}
	}, []);

	const value = useMemo(
		() => ({
			user,
			loading,
			error,
			logout,
		}),
		[user, loading, error, logout],
	);

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

UserProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
