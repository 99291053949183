export const HOME_URI = '/';
export const HOME_PATH = HOME_URI;

export const DASHBOARD_URI = '/dashboard/:id?';
export const DASHBOARD_PATH = DASHBOARD_URI;

export const LOGIN_URI = '/login';
export const LOGIN_PATH = LOGIN_URI;

export const LOGIN_FINISH_URI = '/finishlogin';
export const LOGIN_FINISH_PATH = LOGIN_FINISH_URI;

export const REDIRECT_URI = '/redirect';
export const REDIRECT_PATH = REDIRECT_URI;
