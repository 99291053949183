import EventEmitter from 'events';

class WebsocketService extends EventEmitter {
	constructor() {
		super();
		this._messages = new Set();
	}

	connect() {
		this.ws = new WebSocket(
			process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:3001',
		);

		this.ws.onopen = () => {
			clearTimeout(this.reconect);
			this.tryConnect = false;

			if (this._messages.size) {
				this._messages.forEach((data) => {
					this.ws.send(data);
					this._messages.delete(data);
				});
			}

			this.interval = setInterval(() => {
				this.ws.send(JSON.stringify({ type: 'ping' }));
			}, 30 * 1000);
		};

		this.ws.onmessage = ({ data: message }) => {
			try {
				const { type = '', data } = JSON.parse(message);
				this.emit(type, data);
			} catch (e) {
				// eslint-disable-next-line
				console.error(e);
			}
		};

		this.ws.onclose = () => {
			clearInterval(this.interval);
			if (this.tryConnect === true) {
				return void 0;
			}

			this.tryConnect = true;

			this.reconect = setTimeout(() => {
				this.tryConnect = false;
				this.connect();
			}, 5000);

			return void 0;
		};
	}

	disconnect() {
		if (this.ws) {
			this.ws.close();
			this.ws = null;
		}
		clearInterval(this.interval);
		clearTimeout(this.reconnectTimeout);
	}

	send(data) {
		if (this.ws.readyState) {
			return this.ws.send(data);
		}

		this._messages.add(data);

		return void 0;
	}
}

export default new WebsocketService();
