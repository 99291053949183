import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
	HOME_PATH,
	REDIRECT_PATH,
	LOGIN_PATH,
	LOGIN_FINISH_PATH,
	DASHBOARD_PATH,
} from '../constants/navigation.constants';
import Suspense from '../components/Suspense';

const Home = lazy(() => import('./Home'));
const Layout = lazy(() => import('./Layout'));
const Redirect = lazy(() => import('./Redirect'));
const Login = lazy(() => import('./Login'));
const LoginFinish = lazy(() => import('./LoginFinish'));
const Dashboard = lazy(() => import('./Dashboard'));

function Router() {
	return (
		<Routes>
			<Route
				path={HOME_PATH}
				element={
					<Suspense>
						<Layout />
					</Suspense>
				}
			>
				<Route
					index
					element={
						<Suspense>
							<Home />
						</Suspense>
					}
				/>
			</Route>
			<Route
				path={DASHBOARD_PATH}
				element={
					<Suspense>
						<Dashboard />
					</Suspense>
				}
			/>
			<Route
				path={LOGIN_PATH}
				element={
					<Suspense>
						<Login />
					</Suspense>
				}
			/>
			<Route
				path={LOGIN_FINISH_PATH}
				element={
					<Suspense>
						<LoginFinish />
					</Suspense>
				}
			/>
			<Route
				path={REDIRECT_PATH}
				element={
					<Suspense>
						<Redirect />
					</Suspense>
				}
			/>
		</Routes>
	);
}

export default Router;
